import { Link } from "gatsby";
import React from "react";

export function Footer() {
  return (
    <div className="site-footer flex flex-col mt-40 py-32 items-center justify-center">
      <div className="flex gap-6">
        <a href="https://www.facebook.com/UncleTeoPR">
          <img className="w-16" src="/facebook.svg" alt="Facebook" />
        </a>
        <a href="https://twitter.com/UncleTEOpr">
          <img className="w-16" src="/twitter.svg" alt="Twitter" />
        </a>
        <a href="https://www.instagram.com/uncleteopr/">
          <img className="w-16" src="/instagram.svg" alt="Instagram" />
        </a>
      </div>
      {/* <div className="mt-8">
        <Link to="/privacy-policy">Privacy Policy</Link>
        {` `}
        <span className="opacity-50 inline-block px-2">|</span>
        <Link to="/cookies"> Cookie Policy</Link>
      </div> */}
    </div>
  );
}
