import { Footer } from "components/Footer";
import React from "react";

export function Page({ children, hideFooter = false }) {
  return (
    <div className="page flex flex-col">
      <div
        className="flex-1"
        style={{ minHeight: "calc(100vh - var(--site-header-height))" }}
      >
        {children}
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
}
