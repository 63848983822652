import { Page } from "components/Page";
import React, { useState } from "react";

export default function WaitingListPage() {
  const [show, setShow] = useState(false);
  return (
    <Page hideFooter>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - var(--site-header-height))",
          background: "#000",
        }}
      >
        <iframe
          style={{
            height: "calc(100vh - var(--site-header-height))",
            width: "100%",
            opacity: show ? 1 : 0,
            transition: "all 0.5s ease",
          }}
          src="https://gmail.us1.list-manage.com/subscribe?u=5c23b293c4463d75e2a9fd5ce&id=b349dab70a"
          onLoad={() => setTimeout(() => setShow(true), 100)}
        />
      </div>
    </Page>
  );
}
